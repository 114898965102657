.panel {
    border-radius: 2px;
    border: 0;

    .variations(~" > .panel-heading", background-color, @lightgrey);
    .shadow-z-1;
}


[class*="panel-"] > .panel-heading {
    color: @darkbg-text;
}
.panel-default, .panel:not([class*="panel-"]) {
    > .panel-heading {
        color: @lightbg-text;
    }
}
.panel-footer {
    background-color: @lightgrey;
}
